export default {
  twitter: "https://twitter.com/unionprotocol",
  blog: "https://medium.com/union-finance",
  website: "https://union.finance/",
  governance: "https://union.finance/governance",
  docs: "https://docs.union.finance/",
  discord: "https://discord.gg/fZSmfUshQD",
  github: "https://github.com/unioncredit",
  data: "https://data.union.finance",
};
